import { Field, FieldValidation, ITransaction, Transaction } from '../../../core/domain/module';
import { Step } from '../../../core/domain/transaction/step';
import { PaymentFrequencies } from '../../../core/enums/module';
import { FieldTypes } from '../../fieldTypes';
import { conditional } from '../../fieldValidations/conditional';
import { maxLength } from '../../fieldValidations/maxLength';
import { required } from '../../fieldValidations/required';
import { DefaultSteps } from '../../default';

export enum SpouseAddOnFields {
	AddSpouse = 'addSpouse',
	SpouseFirstName = 'spouseFirstName',
	SpouseLastName = 'spouseLastName'
}

export const isAddingSpouse = (transactionFunc: () => ITransaction) => {
	return transactionFunc().Steps[DefaultSteps.SpouseAddOn].Fields[SpouseAddOnFields.AddSpouse].GetValue() === 'checked';
};

export const spouseValidations: (
	validations: FieldValidation[],
	transactionFunc: () => ITransaction
) => FieldValidation[] = (validations, transactionFunc) => {
	return validations.map((validation) => {
		return conditional(() => isAddingSpouse(transactionFunc), validation);
	});
};

const fields = (transactionFunc: () => ITransaction) => {
	return {
		[SpouseAddOnFields.AddSpouse]: new Field(
			SpouseAddOnFields.AddSpouse,
			'Give your spouse the same peace of mind. Add them now and they’ll receive the same level of membership at half the price!',
			FieldTypes.Checkbox,
			'',
			[]
		),
		[SpouseAddOnFields.SpouseFirstName]: new Field(
			SpouseAddOnFields.SpouseFirstName,
			'Spouse first name',
			FieldTypes.Text,
			"Spouse's first name is required.",
			spouseValidations([required, maxLength(40)], transactionFunc)
		),
		[SpouseAddOnFields.SpouseLastName]: new Field(
			SpouseAddOnFields.SpouseLastName,
			'Spouse last name',
			FieldTypes.Text,
			"Spouse's last name is required.",
			spouseValidations([required, maxLength(80)], transactionFunc)
		)
	};
};

export const SpouseAddOn = (
	transactionFunc = () => Transaction.Instance()
) =>
	new Step(fields(transactionFunc), async () => {
		const transaction = transactionFunc();
		const selectedFrequency = transaction.Data.purchaseProducts?.primary?.attributes.paymentFrequency ||
			transaction.PrimarySub?.attributes.paymentFrequency;
		const addSpouseValue = transaction.Steps[DefaultSteps.SpouseAddOn].Fields[SpouseAddOnFields.AddSpouse].GetValue();
		const shouldAddSpouse = addSpouseValue === 'checked';

		if (shouldAddSpouse) {
			transaction.Data.spouseInfo = {
				firstName: transaction.Steps[DefaultSteps.SpouseAddOn].Fields[SpouseAddOnFields.SpouseFirstName].GetValue(),
				lastName: transaction.Steps[DefaultSteps.SpouseAddOn].Fields[SpouseAddOnFields.SpouseLastName].GetValue()
			};

			transaction.SetPurchaseProducts(selectedFrequency as PaymentFrequencies, shouldAddSpouse);
		} else {
			delete transaction.Data.spouseInfo;
			transaction.SetPurchaseProducts(selectedFrequency as PaymentFrequencies, false);
		}

		return {
			wasSuccessful: !shouldAddSpouse || !!transaction.Data.purchaseProducts?.spouse
		};
	});
