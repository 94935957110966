import { Levels } from './levels';
import { ProductTiers } from './productTiers';

export const productTierLevels: Record<ProductTiers, Levels | null> = {
	[ProductTiers.CCM]: null,
	[ProductTiers.Silver]: null,
	[ProductTiers.GenericSpouse]: null,
	[ProductTiers.Elite]: Levels.Elite,
	[ProductTiers.EliteSpouse]: Levels.Elite,
	[ProductTiers.Platinum]: Levels.Platinum,
	[ProductTiers.PlatinumSpouse]: Levels.Platinum,
	[ProductTiers.Gold]: Levels.Gold,
	[ProductTiers.GoldSpouse]: Levels.Gold,
	[ProductTiers.Gold15]: Levels.Gold,
	[ProductTiers.GoldSpouse15]: Levels.Gold,
	[ProductTiers.Platinum15]: Levels.Platinum,
	[ProductTiers.PlatinumSpouse15]: Levels.Platinum,
	[ProductTiers.Elite15]: Levels.Elite,
	[ProductTiers.EliteSpouse15]: Levels.Elite,
	[ProductTiers.Gold15Loyalty]: Levels.Gold,
	[ProductTiers.GoldSpouse15Loyalty]: Levels.Gold,
	[ProductTiers.Platinum15Loyalty]: Levels.Platinum,
	[ProductTiers.PlatinumSpouse15Loyalty]: Levels.Platinum,
	[ProductTiers.Elite15Loyalty]: Levels.Elite,
	[ProductTiers.EliteSpouse15Loyalty]: Levels.Elite
};

export const levelProductTiers: Record<Levels, ProductTiers> = {
	[Levels.Gold]: ProductTiers.Gold,
	[Levels.Platinum]: ProductTiers.Platinum,
	[Levels.Elite]: ProductTiers.Elite
};

export const levelUpgrades: Record<Levels, Levels[]> = {
	[Levels.Gold]: [Levels.Platinum, Levels.Elite],
	[Levels.Platinum]: [Levels.Elite],
	[Levels.Elite]: []
};
