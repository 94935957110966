import React from 'react';
import { Levels } from '../enums/levels';

export const membershipBenefits: Record<
	Levels,
	{
		monthlyPrice: number;
		annualPrice: number;
		mostPopular: boolean;
		benefitSections: Array<{
			heading: string;
			subheading: string;
			benefits: Array<{ label: string | JSX.Element; value: string | boolean }>;
		}>;
	}
> = {
	[Levels.Gold]: {
		monthlyPrice: 39,
		annualPrice: 399,
		mostPopular: false,
		benefitSections: [
			{
				heading: 'Education',
				subheading:
					'Access to thousands of lifesaving resources that supercharge your self-defense awareness, readiness, and peace of mind.',
				benefits: [
					{ label: <span><em>Concealed Carry Magazine</em><br /> Archive</span>, value: '1 Year' },
					{ label: <span>Lifesaving Guides, eBooks,<br /> &amp; Checklists</span>, value: '30+' },
					{ label: <span>"Ask an Attorney"<br /> Video Series</span>, value: false },
					{ label: <span>"Ask an Attorney"<br /> Complete Archive</span>, value: false }
				]
			},
			{
				heading: 'Training',
				subheading:
					'Elevate your self-defense skills and abilities with full access to expert-led training that helps prepare you for any life-threatening situation.',
				benefits: [
					{ label: <span>Course Lessons</span>, value: '34+' },
					{ label: <span>Video Series Episodes</span>, value: '53+' },
					{ label: <span>Online Training &amp;<br /> Qualification</span>, value: 'Level 1' }
				]
			},
			{
				heading: 'Insurance',
				subheading: 'Comprehensive Self-Defense Liability Insurance, backed by a 24/7/365 Critical Response Team.',
				benefits: [
					{ label: <span>Self-Defense Liability Insurance<br /> for Defense Expenses: No Limit<sup>1</sup></span>, value: true },
					{ label: <span>Annual Aggregate Liability Limit<br /> for Compensatory Damages<sup>1</sup>:<br /> $2,000,000</span>, value: true },
					{ label: <span>Your Choice of Criminal<br /> Defense Attorney<sup>1</sup></span>, value: true },
					{ label: <span>Coverage for Lawful Acts of<br /> Self-Defense<sup>1</sup></span>, value: true },
					{ label: <span>Self-Defense Liability Insurance<br /> for Defense Expenses: No Limit<sup>1</sup></span>, value: true },
					{ label: <span>24/7/365 Access to the Delta<br /> Defense Critical Response Team</span>, value: true }
				]
			}
		]
	},
	[Levels.Platinum]: {
		monthlyPrice: 49,
		annualPrice: 499,
		mostPopular: true,
		benefitSections: [
			{
				heading: 'Education',
				subheading:
					'Access to thousands of lifesaving resources that supercharge your self-defense awareness, readiness, and peace of mind.',
				benefits: [
					{ label: <span><em>Concealed Carry Magazine</em><br /> Archive</span>, value: '5 Years' },
					{ label: <span>Lifesaving Guides, eBooks,<br /> &amp; Checklists</span>, value: '40+' },
					{ label: <span>"Ask an Attorney"<br /> Video Series</span>, value: true },
					{ label: <span>"Ask an Attorney"<br /> Complete Archive</span>, value: false }
				]
			},
			{
				heading: 'Training',
				subheading:
					'Elevate your self-defense skills and abilities with full access to expert-led training that helps prepare you for any life-threatening situation.',
				benefits: [
					{ label: <span>Course Lessons</span>, value: '131+' },
					{ label: <span>Video Series Episodes</span>, value: '145+' },
					{ label: <span>Online Training &amp;<br /> Qualification</span>, value: 'Level 1-2' }
				]
			},
			{
				heading: 'Insurance',
				subheading: 'Comprehensive Self-Defense Liability Insurance, backed by a 24/7/365 Critical Response Team.',
				benefits: [
					{ label: <span>Self-Defense Liability Insurance<br /> for Defense Expenses: No Limit<sup>1</sup></span>, value: true },
					{ label: <span>Annual Aggregate Liability Limit<br /> for Compensatory Damages<sup>1</sup>:<br /> $2,000,000</span>, value: true },
					{ label: <span>Your Choice of Criminal<br /> Defense Attorney<sup>1</sup></span>, value: true },
					{ label: <span>Coverage for Lawful Acts of<br /> Self-Defense<sup>1</sup></span>, value: true },
					{ label: <span>Self-Defense Liability Insurance<br /> for Defense Expenses: No Limit<sup>1</sup></span>, value: true },
					{ label: <span>24/7/365 Access to the Delta<br /> Defense Critical Response Team</span>, value: true }
				]
			}
		]
	},
	[Levels.Elite]: {
		monthlyPrice: 59,
		annualPrice: 599,
		mostPopular: false,
		benefitSections: [
			{
				heading: 'Education',
				subheading:
					'Access to thousands of lifesaving resources that supercharge your self-defense awareness, readiness, and peace of mind.',
				benefits: [
					{ label: <span><em>Concealed Carry Magazine</em><br /> Archive</span>, value: 'All Access' },
					{ label: <span>Lifesaving Guides, eBooks,<br /> &amp; Checklists</span>, value: '80+' },
					{ label: <span>"Ask an Attorney"<br /> Video Series</span>, value: true },
					{ label: <span>"Ask an Attorney"<br /> Complete Archive</span>, value: true }
				]
			},
			{
				heading: 'Training',
				subheading:
					'Elevate your self-defense skills and abilities with full access to expert-led training that helps prepare you for any life-threatening situation.',
				benefits: [
					{ label: <span>Course Lessons</span>, value: '131+' },
					{ label: <span>Video Series Episodes</span>, value: '318+' },
					{ label: <span>Online Training &amp;<br /> Qualification</span>, value: 'Level 1-3' }
				]
			},
			{
				heading: 'Insurance',
				subheading: 'Comprehensive Self-Defense Liability Insurance, backed by a 24/7/365 Critical Response Team.',
				benefits: [
					{ label: <span>Self-Defense Liability Insurance<br /> for Defense Expenses: No Limit<sup>1</sup></span>, value: true },
					{ label: <span>Annual Aggregate Liability Limit<br /> for Compensatory Damages<sup>1</sup>:<br /> $2,000,000</span>, value: true },
					{ label: <span>Your Choice of Criminal<br /> Defense Attorney<sup>1</sup></span>, value: true },
					{ label: <span>Coverage for Lawful Acts of<br /> Self-Defense<sup>1</sup></span>, value: true },
					{ label: <span>Self-Defense Liability Insurance<br /> for Defense Expenses: No Limit<sup>1</sup></span>, value: true },
					{ label: <span>24/7/365 Access to the Delta<br /> Defense Critical Response Team</span>, value: true }
				]
			}
		]
	}
};
