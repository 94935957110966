export enum ExternalServicesIds {
  StagingCCM = 'b54d48f1-942f-4af3-b7a4-389ec1032b78',
  ProductionCCMOne = '19b6c3bf-4195-4174-bdd5-1d9fe3063149',
  ProductionCCMTwo = 'a3b9a81f-f227-45e6-95fb-5608f00d2061'
}

export enum ProductIds {
  StagingCCM = '7bc1cafe-d6de-11ec-90c1-0242ac120006',
  ProductionCCM = '067aff0c-f9f5-11eb-9b8f-0242ac120004'
}
