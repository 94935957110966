export enum SwiftWindFunnelIds {
  SixTacticalGuides = '3635',
  SevenTacticalResources = '3651',
  FiftyDollarsOffTraining = '3655',
  MemberCard = '3657',
  PartnerDiscounts = '3661',
  Cogs2 = '3761',
  Cogs3 = '3762',
  Cogs4 = '3763',
  Cogs5 = '3766'
}
