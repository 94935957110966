export enum ProductTiers {
	CCM = 'Concealed Carry Magazine',
	Silver = 'Silver Membership',
	Gold = 'Gold Membership',
	Platinum = 'Platinum Membership',
	Elite = 'Elite Membership',
	GenericSpouse = 'Spouse Membership',
	GoldSpouse = 'Gold Spouse Membership',
	PlatinumSpouse = 'Platinum Spouse Membership',
	EliteSpouse = 'Elite Spouse Membership',
	Gold15 = 'Gold 15.0 Membership',
	GoldSpouse15 = 'Gold 15.0 Spouse Membership',
	Platinum15 = 'Platinum 15.0 Membership',
	PlatinumSpouse15 = 'Platinum 15.0 Spouse Membership',
	Elite15 = 'Elite 15.0 Membership',
	EliteSpouse15 = 'Elite 15.0 Spouse Membership',
	Gold15Loyalty = 'Gold 15.0 Loyalty Membership',
	GoldSpouse15Loyalty = 'Gold 15.0 Loyalty Spouse Membership',
	Platinum15Loyalty = 'Platinum 15.0 Loyalty Membership',
	PlatinumSpouse15Loyalty = 'Platinum 15.0 Loyalty Spouse Membership',
	Elite15Loyalty = 'Elite 15.0 Loyalty Membership',
	EliteSpouse15Loyalty = 'Elite 15.0 Loyalty Spouse Membership',
}
