/* eslint-disable max-lines */
import type { Result } from '../../domain/result';
import type { APIDataArray, ApiResult } from '@delta-defense/delta-development-kit/apiResult';
import type { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';
import type { BundleProduct } from '../../models/bundleProduct';
import type { ITransaction } from '../../domain/transaction/ITransaction';
import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { IProductsService, ProductsService, Product } from '@delta-defense/delta-development-kit/products/module';
import { Transaction } from '../../domain/transaction/transaction';
import { FeatureFlags, Levels, Messages, ProductTiers } from '../../enums/module';
import { IJasmine, Jasmine } from '../jasmine/jasmine';
import { ITracking, Tracking } from '../tracking/tracking';
import { funnelIdsThatIgnoreBundlesAltogether, funnelIdsThatIgnoreDefaultBundles } from './funnelConfigurations';
import { AmacIntegration } from '../amacIntegration/amacIntegration';

export interface IBundle {
	allBundlesDisabled(): Promise<boolean>;
	getBundleId(tier: ProductTiers): Promise<string>;
	getBundleIdFromFunnel(tier: ProductTiers): Promise<string>;
	funnelShouldUseSurpriseBundle(): Promise<boolean>;
	getBundleIdsForAllLevels(): Promise<Record<Levels, string>>;
	getBundleProductsForAllLevels(): Promise<Result<Record<Levels, BundleProduct[]>>>;
	setBundleProductsInTransaction(overrideExisting?: boolean): Promise<Result<BundleProduct[]>>;
	getDefaultBundleProductsForAllLevels(): Promise<Result<Record<Levels, BundleProduct[]>>>;
}

export class Bundle implements IBundle {
	private static instance: IBundle | null = null;

	public static Instance(
		jasmine = Jasmine.Instance(),
		transactionFunc = () => Transaction.Instance(),
		features = FeatureFlagService.Instance(),
		products = ProductsService.Instance(),
		tracking = Tracking.Instance(),
		amacIntegrationEnabled = AmacIntegration.isEnabled()
	): IBundle {
		return (
			this.instance ||
			(this.instance = new Bundle(jasmine, transactionFunc, features, products, tracking, amacIntegrationEnabled))
		);
	}

	public static Destroy = () => (Bundle.instance = null);

	private constructor(
		private jasmine: IJasmine,
		private transactionFunc: () => ITransaction,
		private features: IFeatureFlagRepository,
		private products: IProductsService,
		private tracking: ITracking,
		private amacIntegrationEnabled: boolean
	) { }

	async allBundlesDisabled(): Promise<boolean> {
		if (
			this.amacIntegrationEnabled ||
			this.features.IsEnabled(FeatureFlags.PreventBonusItems) ||
			(this.transactionFunc().PrimarySub?.attributes?.type === 'membership' &&
				!this.features.IsEnabled(FeatureFlags.BundlesForMembers))
		) {
			return true;
		} else {
			const funnelId = (await this.tracking.getFunnelId())?.value || '';
			return funnelIdsThatIgnoreBundlesAltogether.includes(funnelId);
		}
	}

	async getBundleId(tier: ProductTiers): Promise<string> {
		if (tier.includes('15.0')) {
			const tierConversionMap: Partial<Record<ProductTiers, ProductTiers>> = {
				[ProductTiers.Gold15]: ProductTiers.Gold,
				[ProductTiers.Platinum15]: ProductTiers.Platinum,
				[ProductTiers.Elite15]: ProductTiers.Elite
			};
			tier = tierConversionMap[tier] as ProductTiers;
		}

		if (await this.allBundlesDisabled()) {
			return '';
		} else if (this.transactionFunc().useDefaultBundle) {
			return await this.getDefaultBundleId(tier);
		} else {
			const funnelId = (await this.tracking.getFunnelId())?.value || '';
			const ignoreBundlesAltogether = () => '';
			const ignoreDefaultSurpriseBundleButUseBundlesOnFunnel = async () => await this.getBundleIdFromFunnel(tier);
			const useBundleFromFunnelOrDefault = async () =>
				(await this.getBundleIdFromFunnel(tier)) || (await this.getDefaultBundleId(tier));

			let getBundleIdMethod: () => string | Promise<string> = useBundleFromFunnelOrDefault;
			if (funnelIdsThatIgnoreBundlesAltogether.includes(funnelId)) {
				getBundleIdMethod = ignoreBundlesAltogether;
			} else if (funnelIdsThatIgnoreDefaultBundles.includes(funnelId) || !this.features.IsEnabled(FeatureFlags.CheckoutBonus)) {
				getBundleIdMethod = ignoreDefaultSurpriseBundleButUseBundlesOnFunnel;
			}
			return await getBundleIdMethod();
		}
	}

	private bundleIdCache: Record<string, Partial<Record<ProductTiers, string | null>> | null> = {};
	private async getBundleIdsFromFunnel(funnelId: string) {
		if (funnelId) {
			if (!Object.keys(this.bundleIdCache).includes(funnelId)) {
				const funnelData = (await this.jasmine.getFunnelData(funnelId)).value;

				if (funnelData) {
					this.bundleIdCache[funnelId] = {
						[ProductTiers.CCM]: funnelData.ccm_bundle_id,
						[ProductTiers.Gold]: funnelData.gold_bundle_id,
						[ProductTiers.Platinum]: funnelData.platinum_bundle_id,
						[ProductTiers.Elite]: funnelData.elite_bundle_id
					};
				} else {
					this.bundleIdCache[funnelId] = null;
				}
			}
		}
		return this.bundleIdCache[funnelId] || null;
	}

	async getBundleIdFromFunnel(tier: ProductTiers) {
		if (await this.allBundlesDisabled()) {
			return '';
		}
		const funnelId = (await this.tracking.getFunnelId())?.value || '';
		const tierBundleData = await this.getBundleIdsFromFunnel(funnelId);
		return tierBundleData?.[tier] || '';
	}

	async getBundleIdsForAllLevels() {
		return {
			[Levels.Gold]: await this.getBundleId(ProductTiers.Gold),
			[Levels.Platinum]: await this.getBundleId(ProductTiers.Platinum),
			[Levels.Elite]: await this.getBundleId(ProductTiers.Elite)
		};
	}

	private bundleProductsCache: Record<string, ApiResult<APIDataArray<Product>> | null> = {};
	// eslint-disable-next-line complexity
	async getBundleProductsForAllLevels(forceDefault = false) {
		if (await this.allBundlesDisabled()) {
			return {
				wasSuccessful: true
			};
		}
		const bundleIds = forceDefault && this.features.IsEnabled(FeatureFlags.CheckoutBonus)
			? await this.getDefaultBundleIdsForAllLevels()
			: await this.getBundleIdsForAllLevels();
		const [goldResult, platinumResult, eliteResult] = await Promise.all<ApiResult<APIDataArray<Product>>>([
			(this.bundleProductsCache[bundleIds[Levels.Gold]] =
				this.bundleProductsCache[bundleIds[Levels.Gold]] ||
				(await this.products.getBundleProducts(bundleIds[Levels.Gold]))),
			(this.bundleProductsCache[bundleIds[Levels.Platinum]] =
				this.bundleProductsCache[bundleIds[Levels.Platinum]] ||
				(await this.products.getBundleProducts(bundleIds[Levels.Platinum]))),
			(this.bundleProductsCache[bundleIds[Levels.Elite]] =
				this.bundleProductsCache[bundleIds[Levels.Elite]] ||
				(await this.products.getBundleProducts(bundleIds[Levels.Elite])))
		]);

		const wasSuccessful = goldResult.wasSuccessful && platinumResult.wasSuccessful && eliteResult.wasSuccessful;

		const bundleIdProductsRecord = wasSuccessful
			? {
					[Levels.Gold]: goldResult.value?.data?.map((e) => e) || [],
					[Levels.Platinum]: platinumResult.value?.data?.map((e) => e) || [],
					[Levels.Elite]: eliteResult.value?.data?.map((e) => e) || []
				}
			: undefined;

		return {
			wasSuccessful,
			value: bundleIdProductsRecord
		};
	}

	async setBundleProductsInTransaction(overrideExisting = false) {
		const transaction = this.transactionFunc();

		if (await this.allBundlesDisabled()) {
			transaction.Data.bundleProducts = [];
			return {
				wasSuccessful: true,
				value: []
			};
		}

		if (transaction.SpouseOnlyPurchase || (!overrideExisting && transaction.Data.bundleProducts?.length)) {
			return {
				wasSuccessful: true,
				value: transaction.Data.bundleProducts
			};
		}

		const bundleId = await this.getBundleId(
			transaction.Data.purchaseProducts?.primary?.attributes.tier || ProductTiers.Gold
		);

		let wasSuccessful = true;
		let value: BundleProduct[] = [];
		if (bundleId) {
			transaction.Message.Publish(Messages.LoadingBundleProducts);
			const bundleResponse = await this.products.getBundleProducts(bundleId);
			transaction.Message.Publish(Messages.Empty);
			wasSuccessful = bundleResponse.wasSuccessful;
			value = transaction.Data.bundleProducts = bundleResponse.value?.data?.map((e) => e) || [];
		} else {
			transaction.Data.bundleProducts = [];
		}

		return {
			wasSuccessful,
			value
		};
	}

	async funnelShouldUseSurpriseBundle() {
		const funnelId = (await this.tracking.getFunnelId())?.value || '';

		if (!funnelId || funnelIdsThatIgnoreDefaultBundles.includes(funnelId)) {
			return false;
		}

		const bundleIdsOnFunnelConfiguration = await this.getBundleIdsFromFunnel(funnelId);
		return !bundleIdsOnFunnelConfiguration || Object.values(bundleIdsOnFunnelConfiguration).every((bundleId) => !bundleId);
	}

	async getDefaultBundleProductsForAllLevels(): Promise<Result<Record<Levels, BundleProduct[]>>> {
		return await this.getBundleProductsForAllLevels(true);
	}

	private defaultBundleCache: Partial<Record<ProductTiers, string>> | undefined;
	private async getDefaultBundleId(tier: ProductTiers): Promise<string> {
		if (!this.defaultBundleCache) {
			const schedulerDataResponse = await this.jasmine.getSchedulerData();
			if (schedulerDataResponse.wasSuccessful && schedulerDataResponse.value) {
				this.defaultBundleCache = {
					[ProductTiers.Gold]: schedulerDataResponse.value['gold_bundle_id'],
					[ProductTiers.Platinum]: schedulerDataResponse.value['platinum_bundle_id'],
					[ProductTiers.Elite]: schedulerDataResponse.value['elite_bundle_id']
				};
			}
		}

		return this.defaultBundleCache?.[tier] || '';
	}

	private async getDefaultBundleIdsForAllLevels(): Promise<Record<Levels, string>> {
		return {
			[Levels.Gold]: await this.getDefaultBundleId(ProductTiers.Gold),
			[Levels.Platinum]: await this.getDefaultBundleId(ProductTiers.Platinum),
			[Levels.Elite]: await this.getDefaultBundleId(ProductTiers.Elite)
		};
	}
}
