/* eslint-disable no-console */
import { safelyExecute } from '../../../utilities/safelyExecute';
import { Analytics, IAnalytics, Steps, InternalEventNames } from '../analytics/module';
import Environments from '@delta-defense/client-environments';
import { Apis } from '@delta-defense/client-environments/environments/enums/apis';
import { IRest, Rest } from '../rest/rest';
import { safelyReturn } from '../../../utilities/safelyReturn';
import { Unverified } from '@delta-defense/delta-development-kit/unverified';
import {
	MessageCallbackFunction,
	RenderParams,
	CardPrepopulateFields,
	ZuoraCallbackEventData,
	ErrorMessageCallback,
	AchPrepopulateFields,
	ApplePaySuccessCallback,
	ApplePayErrorCallback
} from './zuoraTypes';

export const NotConfiguredError = 'You must configure Zuora before rendering a payment form.';
const submissionDuration = 15000;

export interface IZuora {
	Configure(successCallback: MessageCallbackFunction, errorCallback: ErrorMessageCallback);
	Render(params: RenderParams, prepopulateFields: CardPrepopulateFields | AchPrepopulateFields);
	RenderApplePay(
		targetQuerySelector: string,
		accountId: string,
		displayAmount: string,
		onSuccess: ApplePaySuccessCallback,
		onError: ApplePayErrorCallback
	): Promise<void>;
	AddMessageCallback(): void;
	Validate(): Promise<boolean>;
	Submit(): void;
}

export class Zuora implements IZuora {
	private static instance: IZuora | null = null;
	public static Instance(
		mainWindow: Window = globalThis.window,
		analytics = Analytics.Instance(),
		rest = Rest.Instance(),
		paymentsProxyEndpoint = Environments.getProxyEndpointForApi(Apis.Payments)
	): IZuora {
		return this.instance || (this.instance = new Zuora(mainWindow, analytics, rest, paymentsProxyEndpoint));
	}
	public static Destroy = () => (Zuora.instance = null);

	private messageCallbackAttached = false;
	private successCallback!: MessageCallbackFunction;
	private errorCallback!: ErrorMessageCallback;
	private messageCallback = (event: Event & { data: ZuoraCallbackEventData; origin: string }) => {
		const hasValidOrigin = window.location.origin === event.origin;
		const shouldActOnEvent =
			this.messageCallbackAttached && event.data && hasValidOrigin && !event.data['pcmPixelPostMessageEvent'];

		if (shouldActOnEvent) {
			this.clearLongRunningSubmissionTimeout();
			this.removeMessageCallback();

			const eventData: ZuoraCallbackEventData = typeof event.data == 'object' ? event.data : JSON.parse(event.data);

			if (eventData.success == 'true') {
				this.successCallback(eventData);
			}
		}
	};

	private submissionDurationTimeout?: NodeJS.Timeout;

	private constructor(
		private mainWindow: Window,
		private analytics: IAnalytics,
		private rest: IRest,
		private paymentsProxyEndpoint: string
	) {}

	public Configure(successCallback: MessageCallbackFunction, errorCallback: ErrorMessageCallback): void {
		this.successCallback = successCallback;
		this.errorCallback = errorCallback;
	}

	public async RenderApplePay(
		targetQuerySelector: string,
		accountId: string,
		displayAmount: string,
		onSuccess: ApplePaySuccessCallback,
		onError: ApplePayErrorCallback
	): Promise<void> {
		await safelyExecute(async () => {
			const zuora = window['Zuora'](
				// eslint-disable-next-line max-len
				'pk_rO0ABXeyAGis7QAFd2ICAApuYV9zYW5kYm94AAc1MDAwMTU2ACA4YWM2ODE5Yjc3NGYyNGJkMDE3NzU3N2IxYzc3N2Q1NQAgOGFjNjkzZGQ5MmQ2ODVhYjAxOTJkYjBiYTkzZDI4YWUAAAGS2wupPgBGMEQCICLnk6CGsg7aBuyOPO0eXnITT3GXtSJQOMptXGC6j9YcAiBucNJmA-jRXEvzcweK08u5R-Hk-IPlCdMllA192LKuHQ=='
			);
			const applePayButton = await zuora.createApplePayButton({
				countryCode: 'US',
				currency: 'USD',
				totalPriceLabel: 'Delta Defense, LLC.',
				amount: displayAmount,
				supportedNetworks: ['amex', 'masterCard', 'discover', 'visa'],
				buttonStyle: 'black',
				buttonType: 'continue',
				locale: 'en-US',
				createPaymentSession: async () => {
					const paymentSessionResponse = await this.rest.Post<
						Unverified<{
							data: {
								type: string;
								id: string;
								attributes: {
									token: string;
								};
							};
						}>
					>(
						`${this.paymentsProxyEndpoint}/api/payment-sessions`,
						JSON.stringify({
							accountId,
							amount: 1,
							processPayment: false
						})
					);
					if (!paymentSessionResponse.ok || !paymentSessionResponse.body?.data?.attributes?.token) {
						const paymentsApiResponse = safelyReturn(() => JSON.stringify(paymentSessionResponse), '');
						this.analytics.TrackInternal({
							event_name: InternalEventNames.FailedToRenderApplePay,
							step: Steps.Four,
							data: {
								reason: 'Request to Payments API for payment session failed.',
								paymentsApiResponse
							}
						});
						return paymentSessionResponse.body?.data?.attributes?.token;
					}
				},
				onComplete: (result) => {
					return result?.success ? onSuccess(result) : onError(result?.error);
				}
			});
			applePayButton.mount(targetQuerySelector);
		});
	}

	public Render(params: RenderParams, prepopulateFields: CardPrepopulateFields | AchPrepopulateFields): void {
		const isConfigured = !!this.successCallback && !!this.errorCallback;
		if (!isConfigured) {
			throw new Error(NotConfiguredError);
		}

		safelyExecute(() => {
			this.mainWindow.Z.renderWithErrorHandler(
				params,
				prepopulateFields,
				this.successCallback,
				(key, code, message) => {
					this.clearLongRunningSubmissionTimeout();
					this.errorCallback(key, code, message);
				}
			);
		});
	}

	public AddMessageCallback(): void {
		if (!this.messageCallbackAttached) {
			this.mainWindow.addEventListener('message', this.messageCallback, false);
			this.messageCallbackAttached = true;
		}
	}

	public Validate(): Promise<boolean> {
		return new Promise((resolve) => {
			this.mainWindow.Z.validate((r) => {
				resolve(r.success);
			});
		});
	}

	public Submit(): void {
		safelyExecute(() => this.mainWindow.Z.submit());

		this.submissionDurationTimeout = setTimeout(() => {
			this.trackLongRunningSubmission();
		}, submissionDuration);
	}

	private removeMessageCallback(): void {
		this.mainWindow.removeEventListener('message', this.messageCallback, false);
		this.messageCallbackAttached = false;
	}

	private trackLongRunningSubmission(): void {
		this.analytics.TrackInternal({
			event_name: InternalEventNames.LongRunningSubmission,
			step: Steps.Four
		});
	}

	private clearLongRunningSubmissionTimeout(): void {
		if (this.submissionDurationTimeout) {
			clearTimeout(this.submissionDurationTimeout);
		}
	}
}
