import { FeatureFlagService, LaunchDarklyRepository } from '@delta-defense/feature-flag-service';
import { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';
import { Analytics } from '../core/services/analytics/analytics';
import { InternalEventNames } from '../core/services/analytics/enums/internalEventNames';
import { BrowserStorage } from '../core/services/module';
import { SessionStorageKeys } from '../core/services/browserStorage/keys/sessionStorageKeys';
import { FeatureFlags } from '../core/enums/featureFlags';
import { getQueryParameters } from './getQueryParameter';
import { shouldExcludeProductIdFromElegantExperience } from './shouldExcludeProductIdFromElegantExperience';

const localStorageKeyPrefix = 'delta_previousFlagValue_';
enum LocallyStoredFlags {
	ElegantCheckout = FeatureFlags.ElegantCheckout
}

export const initializeFeatureFlagService = (
	mainWindow = globalThis.window,
	analytics = Analytics.Instance(),
	storage = BrowserStorage.SessionInstance()
): IFeatureFlagRepository => {
	const getCustomAttributes = (): Record<string, string> => {
		const paramsObject = {};
		const params = getQueryParameters(mainWindow.location.search);
		Array.from(params).forEach((p) => (paramsObject[p[0]] = p[1]));
		const newMemberPricePaidSet = !!storage.getItem(SessionStorageKeys.NewMemberPricePaid);

		const previousFlagValues = {};
		Object.values(LocallyStoredFlags).forEach(flag => {
			const lastValue = localStorage.getItem(`${localStorageKeyPrefix}${flag}`);
			if (lastValue) {
				previousFlagValues[flag] = lastValue;
			}
		});

		return {
			...paramsObject,
			...previousFlagValues,
			newMember: newMemberPricePaidSet.toString(),
			startingPath: location.pathname,
			excludeFromElegant: shouldExcludeProductIdFromElegantExperience().toString()
		};
	};

	const timeoutCallback = () => {
		analytics.TrackInternal({ event_name: InternalEventNames.FeatureFlagsTimeout });
	};
	const flagsLoadedCallback = (flags: any) => {
		analytics.TrackInternal({ event_name: InternalEventNames.FeatureFlagsLoaded, data: flags });
	};
	const thirdPartyRepository = new LaunchDarklyRepository(getCustomAttributes, timeoutCallback, flagsLoadedCallback);
	const featureFlagsChecked: string[] = [];
	return FeatureFlagService.Instance(thirdPartyRepository, (flag, value) => {
		if (!value && value !== 0) {
			value = false;
		}
		if (!featureFlagsChecked.includes(flag)) {
			analytics.TrackInternal({
				event_name: InternalEventNames.FeatureFlagChecked,
				data: { flag, value: value as any }
			});
			featureFlagsChecked.push(flag);

			if (Object.values(LocallyStoredFlags).includes(flag as LocallyStoredFlags)) {
				const key = `${localStorageKeyPrefix}${flag}`;
				localStorage.setItem(key, value as any);
			}
		}
	});
};
