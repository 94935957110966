export enum AuthorizedSideGradeProducts {
	Gold15PercentOffAnnual = '2fdf5caa-dfff-11ef-a2fd-b69d36a53012',
	Gold15PercentOffMonthly = '2fea80d0-dfff-11ef-b530-b69d36a53012',
	STAGING_Gold15PercentOffAnnual = '0851a1d8-d8ee-11ef-b0ba-e6f5275e0c7d',
	STAGING_Gold15PercentOffMonthly = '086d7a98-d8ee-11ef-a42e-e6f5275e0c7d',
	Platinum15PercentOffAnnual = '2fb6431a-dfff-11ef-b617-b69d36a53012',
	Platinum15PercentOffMonthly = '2fbc0d36-dfff-11ef-a26f-b69d36a53012',
	STAGING_Platinum15PercentOffAnnual = '076a6b6a-d8ee-11ef-bcd6-e6f5275e0c7d',
	STAGING_Platinum15PercentOffMonthly = '07860e2e-d8ee-11ef-ab4d-e6f5275e0c7d',
	Elite15PercentOffAnnual = '2f9325ce-dfff-11ef-abd0-b69d36a53012',
	Elite15PercentOffMonthly = '2f96a91a-dfff-11ef-bf75-b69d36a53012',
	STAGING_Elite15PercentOffAnnual = '0681de2c-d8ee-11ef-805c-e6f5275e0c7d',
	STAGING_Elite15PercentOffMonthly = '069e25aa-d8ee-11ef-ac86-e6f5275e0c7d'
}
