export enum ElegantExcludedProductIds {
  StagingCCMId1 = '7bc1cafe-d6de-11ec-90c1-0242ac120006',
  StagingCCMExternalId1 = '8ac680067c7cf486017c83aab64b4583',
  StagingCCMExternalServicesId1 = '6d9e129a-0a3d-4b68-bcce-54eec7658622',
  StagingDigitalOnlyCCMId = 'e5f23df8-0ffd-11ec-a8a3-02420a020562',
  StagingDigitalOnlyCCMExternalId = '8ac691da7b0bf3f6017b11a37b3f0678',
  StagingDigitalOnlyCCMExternalServicesId = '3a86ed8f-bd26-446c-b523-1e2866f63f97',
  StagingCCMId2 = '9a500476-d6df-11ec-abb9-0242ac120006',
  StagingCCMExternalId2 = '8ac69b1d80d1a2ef0180d2982e80373c',
  StagingCCMExternalServicesId2 = 'b8f6008d-815e-48af-8c74-36083c14d7b1',
  StagingCCMId3 = 'e5ed65da-0ffd-11ec-9f90-02420a020562',
  StagingCCMExternalId3 = '8ac691da7b0bf3f6017b11a37b280676',
  StagingCCMExternalServicesId3 = 'b54d48f1-942f-4af3-b7a4-389ec1032b78',
  ProductionCCMId1 = 'bf08e4cc-70b0-11eb-8d5f-02420a0201b3',
  ProductionCCMExternalId1 = '2c92a0ff596d3b4801598f4b162318de',
  ProductionCCMExternalServicesId1 = 'a3b9a81f-f227-45e6-95fb-5608f00d2061',
  ProductionCCMId2 = '170af6ec-15a2-11ec-86e3-02420a00013f',
  ProductionCCMExternalId2 = '2c92a0077bde3634017be5eb1f0004ff',
  ProductionCCMExternalServicesId2 = '2ca1a72f-620e-4ed9-9d0b-56165db146c7',
  ProductionCCMId3 = '067aff0c-f9f5-11eb-9b8f-0242ac120004',
  ProductionCCMExternalId3 = '2c92a0107b2f8eca017b3099afea6587',
  ProductionCCMExternalServicesId3 = '19b6c3bf-4195-4174-bdd5-1d9fe3063149',
  ProductionCCMId4 = 'c25e665e-87fa-11eb-a668-02420a020cf8',
  ProductionCCMExternalId4 = '2c92a0ff596d3b4801598f4b163018e0',
  ProductionCCMExternalServicesId4 = 'a5dc9e90-d9ea-4618-9a90-029a215d8f63',
  StagingSpouseAnnualId1 = 'e5cb83a2-0ffd-11ec-8457-02420a020562',
  StagingSpouseAnnualExternalId1 = '8ac68bab77fc9af90177fe222a5a1953',
  StagingSpouseAnnualExternalServicesId1 = 'fe23c4c3-64d7-45eb-91a6-bfdeab2a393a',
  StagingSpouseMonthlyId = 'e5ce9588-0ffd-11ec-b2ca-02420a020562',
  StagingSpouseMonthlyExternalId = '8ac68bab77fc9af90177fe222a20194b',
  StagingSpouseMonthlyExternalServicesId = 'cdec8e39-d095-4ace-8d70-59ac1ac6054d',
  StagingSpouseAnnualId2 = 'e5851048-0ffd-11ec-9afc-02420a020562',
  StagingSpouseAnnualExternalId2 = '8ac68bab77fc9af90177fe222a901959',
  StagingSpouseAnnualExternalServicesId2 = 'eaa4e7a4-a09b-45e2-adbb-1b5b864e03a5',
  StagingSpouseAnnualId3 = 'e5eab98e-0ffd-11ec-8905-02420a020562',
  StagingSpouseAnnualExternalId3 = '8ac68bab77fc9af90177fe222a3c194f',
  StagingSpouseAnnualExternalServicesId3 = '5a333aa2-58a7-4e24-a218-e92b7d8a1d8c',
  StagingPlatinumSpouseAnnualId = 'e5ac0ed2-0ffd-11ec-8d0d-02420a020562',
  StagingPlatinumSpouseAnnualExternalId = '8ac68bab77fc9af90177fe222acc1961',
  StagingPlatinumSpouseAnnualExternalServicesId = 'fc1916e7-da1e-446b-af59-735f866a5ce1',
  StagingSpouseAnnualId4 = 'e58860d6-0ffd-11ec-9424-02420a020562',
  StagingSpouseAnnualExternalId4 = '8ac68bab77fc9af90177fe222a2e194d',
  StagingSpouseAnnualExternalServicesId4 = '8ce229e3-aae9-417b-8060-94a17b90a04a',
  StagingSpouseAnnualId5 = 'e5a8c4c0-0ffd-11ec-82ae-02420a020562',
  StagingSpouseAnnualExternalId5 = '8ac68bab77fc9af90177fe222abd195f',
  StagingSpouseAnnualExternalServicesId5 = '9dee83b6-a291-49ee-a4f5-ad66a3e8b77b',
  StagingSpouseAnnual15Id1 = '083184d4-d8ee-11ef-b9fa-e6f5275e0c7d',
  StagingSpouseAnnual15ExternalId1 = '86c96a4bb9b9497ecdc49fdf8ff20000',
  StagingSpouseAnnual15Id2 = '074a4236-d8ee-11ef-8b16-e6f5275e0c7d',
  StagingSpouseAnnual15ExternalI2 = '86c96a4bb1f9497ecdc49fd67f640000',
  StagingSpouseAnnual15Id3 = '072aa886-d8ee-11ef-9615-e6f5275e0c7d',
  StagingSpouseAnnual15ExternalI3 = '86c9844dddd9497ecdd49fd5e8b90000',
  StagingSpouseAnnual15Id4 = '065bf0ea-d8ee-11ef-93cf-e6f5275e0c7d',
  StagingSpouseAnnual15ExternalI4 = '86c9ed696849497d76849f727a790000',
  StagingSpouseAnnual15Id5 = '063aaaa2-d8ee-11ef-b3e4-e6f5275e0c7d',
  StagingSpouseAnnual15ExternalI5 = '86c9844dd339497ecdd49f7126180000',
  ProductionSpouseAnnualElite15Id = '2f8b03a8-dfff-11ef-abf3-b69d36a53012',
  ProductionSpouseAnnualElite15ExternalId = '8a129c2594640b3c0194663fa57e1cd5',
  ProductionSpouseMonthlyElite15Id = '2f8f203c-dfff-11ef-b26f-b69d36a53012',
  ProductionSpouseMonthlyElite15ExternalId = '8a129b6994640b3d019466410b7a093b',
  ProductionSpouseAnnualPlatinum15Id = '2fad4ba2-dfff-11ef-ab5b-b69d36a53012',
  ProductionSpouseAnnualPlatinum15ExternalId = '8a129041946ed9b00194758e74004ddc',
  ProductionSpouseMonthlyPlatinum15Id = '2fb227b2-dfff-11ef-b609-b69d36a53012',
  ProductionSpouseMonthlyPlatinum15ExternalId = '8a129041946ed9b001947591962f5195',
  ProductionSpouseAnnualGold15Id = '2fd4aad0-dfff-11ef-b490-b69d36a53012',
  ProductionSpouseAnnualGold15ExternalId = '8a1280be946eb895019475bd9bb823be',
  ProductionSpouseMonthlyGold15Id = '2fdab9fc-dfff-11ef-a763-b69d36a53012',
  ProductionSpouseMonthlyGold15ExternalId = '8a129946946ed9b4019475bca4c2489e',
}
