import { SwiftWindFunnelIds, Levels } from '../core/enums/module';

enum AllLevel {
	All = 'all'
}

type LevelsPlusAll = Levels | AllLevel;
type SwiftWindValue = { numberOfItems: number; monetaryValue?: number };
type SwiftWindTierConfig<T = SwiftWindValue | Record<Levels, SwiftWindValue>> = {
	description: string;
	imgSrc: string;
	tier: LevelsPlusAll;
	value?: T;
};

export type SwiftWindConfig = {
	[AllLevel.All]?: SwiftWindTierConfig,
	[Levels.Gold]?: SwiftWindTierConfig<SwiftWindValue>,
	[Levels.Platinum]?: SwiftWindTierConfig<SwiftWindValue>,
	[Levels.Elite]?: SwiftWindTierConfig<SwiftWindValue>,
};

const cogsConfig: SwiftWindConfig = {
	[Levels.Gold]: {
		description: 'Home Defense Core Training',
		imgSrc: '/img/cogs/all_levels.jpg',
		tier: Levels.Gold
	},
	[Levels.Platinum]: {
		description: '4 Bonus Training Modules',
		imgSrc: '/img/cogs/four_bonus_modules.jpg',
		tier: Levels.Platinum
	},
	[Levels.Elite]: {
		description: 'Live Home Defense Q&A Sessions',
		imgSrc: '/img/cogs/live_qa_access.jpg',
		tier: Levels.Elite
	}
};

export const swiftWindConfigurations: Record<SwiftWindFunnelIds, SwiftWindConfig> = {
	[SwiftWindFunnelIds.SixTacticalGuides]: {
		all: {
			description: 'Tactical Guides and Book',
			imgSrc: 'https://www.usconcealedcarry.com/wp-content/uploads/2024/06/Checkout-Drawer-2-PDF-as-Bonus.png',
			value: {
				numberOfItems: 6,
				monetaryValue: 192
			},
			tier: AllLevel.All
		}
	},
	[SwiftWindFunnelIds.SevenTacticalResources]: {
		all: {
			description: 'Tactical Resources',
			imgSrc: 'https://www.usconcealedcarry.com/wp-content/uploads/2024/06/Checkout-Drawer-2-PDF-as-Bonus.png',
			value: {
				[Levels.Gold]: {
					numberOfItems: 7,
					monetaryValue: 199
				},
				[Levels.Platinum]: {
					numberOfItems: 9,
					monetaryValue: 373
				},
				[Levels.Elite]: {
					numberOfItems: 10,
					monetaryValue: 410
				}
			},
			tier: AllLevel.All
		}
	},
	[SwiftWindFunnelIds.FiftyDollarsOffTraining]: {
		all: {
			description: '$50 OFF In-Person Firearms Training',
			imgSrc: 'https://www.deltadefense.com/jasmine-assets/img/202408/50-dollar-bill.webp',
			value: {
				numberOfItems: 1
			},
			tier: AllLevel.All
		}
	},
	[SwiftWindFunnelIds.MemberCard]: {
		all: {
			description: 'Member Card with Post Incident Instructions',
			imgSrc: 'https://www.usconcealedcarry.com/wp-content/uploads/2024/08/membership-card-checkout.png',
			value: {
				numberOfItems: 1
			},
			tier: AllLevel.All
		}
	},
	[SwiftWindFunnelIds.PartnerDiscounts]: {
		all: {
			description: '$1,000+ in Self-Defense Discounts',
			imgSrc: 'https://www.usconcealedcarry.com/wp-content/uploads/2024/09/self-defense-discounts.webp',
			value: {
				numberOfItems: 1
			},
			tier: AllLevel.All
		}
	},
	[SwiftWindFunnelIds.Cogs2]: cogsConfig,
	[SwiftWindFunnelIds.Cogs3]: cogsConfig,
	[SwiftWindFunnelIds.Cogs4]: cogsConfig,
	[SwiftWindFunnelIds.Cogs5]: cogsConfig
};

export type SwiftWindBonus = {
	description: string;
	imgSrc: string;
	numberOfItems: number;
	monetaryValue: number;
	tier: LevelsPlusAll;
};

const swiftWindTierBonusFactory = (
	configuration: SwiftWindConfig,
	tierToBuild: LevelsPlusAll,
	level: LevelsPlusAll
): SwiftWindBonus | null => {
	const tierConfig = configuration[tierToBuild];
	const levelsValueMap: Record<LevelsPlusAll, number> = {
		[AllLevel.All]: 0,
		[Levels.Gold]: 1,
		[Levels.Platinum]: 2,
		[Levels.Elite]: 3
	};

	if (!tierConfig || levelsValueMap[level] < levelsValueMap[tierToBuild]) {
		return null;
	}

	const value: SwiftWindValue | undefined = tierConfig.value?.[level] || tierConfig.value;

	return {
		description: tierConfig.description,
		imgSrc: tierConfig.imgSrc,
		monetaryValue: value?.monetaryValue || 0,
		numberOfItems: value?.numberOfItems || 1,
		tier: tierToBuild
	};
};

export const getSwiftWindBonus = (
	funnelOrConfig: SwiftWindFunnelIds | SwiftWindConfig,
	level: LevelsPlusAll
): SwiftWindBonus[] | undefined => {
	const configurations = typeof funnelOrConfig == 'string' ? swiftWindConfigurations[funnelOrConfig] : funnelOrConfig;
	if (configurations) {
		const bonuses: SwiftWindBonus[] = [];
		Object.keys(configurations).forEach(tier => {
			const bonus = swiftWindTierBonusFactory(configurations, tier as LevelsPlusAll, level);
			bonus && (bonuses.push(bonus));
		});
		return bonuses;
	}
};
