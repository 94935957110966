import { Environments } from '@delta-defense/client-environments';

export enum Levels {
	Gold = 'gold',
	Platinum = 'platinum',
	Elite = 'elite'
}

export enum FifteenPricingProductIds {
	Gold = '2fd01b46-dfff-11ef-9d13-b69d36a53012',
	Platinum = '2fa81f88-dfff-11ef-8154-b69d36a53012',
	Elite = '2f86e5de-dfff-11ef-8ee6-b69d36a53012',
	StagingGold = '07f59f1e-d8ee-11ef-bc08-e6f5275e0c7d',
	StagingPlatinum = '070e7dfa-d8ee-11ef-8e30-e6f5275e0c7d',
	StagingElite = '061ed76e-d8ee-11ef-b7ec-e6f5275e0c7d'
}

const isProduction = Environments.isProduction();
export const levelsProductIdMap: Record<Levels, FifteenPricingProductIds> = {
	[Levels.Gold]: isProduction ? FifteenPricingProductIds.Gold : FifteenPricingProductIds.StagingGold,
	[Levels.Platinum]: isProduction ? FifteenPricingProductIds.Platinum : FifteenPricingProductIds.StagingPlatinum,
	[Levels.Elite]: isProduction ? FifteenPricingProductIds.Elite : FifteenPricingProductIds.StagingElite
};
