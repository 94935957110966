import { Result } from '../core/domain/result';
import { ITransaction } from '../core/domain/transaction/ITransaction';
import { SubscriptionProduct } from '../core/models/subscription/subscriptionProduct';
import { Subscriptions } from '../core/services/subscriptions/subscriptions';

enum PricingTier {
	X = 'x',
	Loyalty = 'version-15-loyalty',
	Fifteen = 'version-15'
}

const productPricingTierPredicateMap: Record<PricingTier, (product: SubscriptionProduct) => boolean> = {
	[PricingTier.Fifteen]: (product) => product.attributes.tags.some(t => t.urlSafe === PricingTier.Fifteen),
	[PricingTier.Loyalty]: (product) => product.attributes.tags.some(t => t.urlSafe === PricingTier.Loyalty),
	[PricingTier.X]: (product) => !product.attributes.tags.some(t => ([PricingTier.Fifteen, PricingTier.Loyalty] as string[]).includes(t.urlSafe))
};

const spouseFrequencyOnlyTiers = [
	PricingTier.X
];

const spouseFrequencyOnlyTierReplacementMap: Partial<Record<PricingTier, PricingTier>> = {
	[PricingTier.X]: PricingTier.Loyalty
};

const filterProductsForTier = (products: SubscriptionProduct[], tier: PricingTier): SubscriptionProduct[] =>
	products.filter(productPricingTierPredicateMap[tier]);

const getApplicableProducts = (transaction: ITransaction, allProducts: SubscriptionProduct[], currentSubscriptionTier: PricingTier): SubscriptionProduct[] => {
	let tier: PricingTier;
	if (transaction.SpouseOnlyPurchase || transaction.IsSameLevelMonthlyToAnnualUpgrade()) {
		tier = currentSubscriptionTier;
	} else {
		tier = spouseFrequencyOnlyTiers.includes(currentSubscriptionTier) ?
			spouseFrequencyOnlyTierReplacementMap[currentSubscriptionTier] as PricingTier : currentSubscriptionTier;
	}
	return filterProductsForTier(allProducts, tier);
};

const getPricingTier = (product: SubscriptionProduct): PricingTier => {
	const mappedTier = Object.entries(productPricingTierPredicateMap)
		.find((kv) => kv[1](product))?.[0] as unknown as PricingTier;
	return mappedTier || PricingTier.X;
};

// eslint-disable-next-line complexity
export const reconcilePricingTiers = async (
	productId: string | null,
	transaction: ITransaction,
	locationSearch: string,
	productsResult: Result<SubscriptionProduct[]> | null,
	subscriptions = Subscriptions.Instance()
) => {
	const productInformationAvailable = productId && (transaction.PrimarySub || transaction.SpouseSub);
	if (!productInformationAvailable) {
		return { locationSearch, productsResult };
	}

	const applicableProductPredicate = (p?: SubscriptionProduct) => !p?.attributes.tags.some(t =>
		['concealed-carry-magazine'].concat(transaction.SpouseOnlyPurchase ? [] : ['spouse']).includes(t.urlSafe));
	const allProducts = (await subscriptions.getAllProducts()).value?.filter(applicableProductPredicate) || [];
	const currentSubscriptionProduct = allProducts?.find(p => p.id === transaction.PrimarySub?.attributes.productId);
	const currentSubscriptionTier = currentSubscriptionProduct ? getPricingTier(currentSubscriptionProduct) : PricingTier.Fifteen;


	if (applicableProductPredicate(currentSubscriptionProduct)) {
		const newProduct = allProducts?.find(p =>
			p.id === productId ||
			p.attributes.externalServicesId === productId ||
			p.attributes.externalId === productId);
		const newProductTier = newProduct ? getPricingTier(newProduct) : PricingTier.Fifteen;

		if (newProductTier === PricingTier.X || currentSubscriptionTier !== newProductTier) {
			const applicableProducts = getApplicableProducts(transaction, allProducts, currentSubscriptionTier);
			const tagsToMatch = ['gold', 'platinum', 'elite', 'monthly', 'annual', 'spouse'].filter(t1 => newProduct?.attributes.tags.find(t2 => t2.urlSafe === t1));
			const correctProductForUserPricing = applicableProducts?.find(
				p => !!tagsToMatch.every(requiredTag => p.attributes.tags.find(t => t.urlSafe === requiredTag)));
			const correctedProductId = correctProductForUserPricing?.id;

			if (correctedProductId && correctedProductId !== productId) {
				locationSearch = locationSearch.replace(productId, correctProductForUserPricing.id);
				productsResult = await transaction.SetProductDataById(correctProductForUserPricing.id);
				history.replaceState({}, '', `${location.href.split('?')[0]}${locationSearch}`);
			}
		}
	}

	return { locationSearch, productsResult };
};

